.list-projects {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
  margin-top: -10px;

  > * {
    padding: 10px 7.5px;
    width: 20%;
  }

  @media (max-width: 1600px) {
    > * {
      width: 25%;
    }
  }

  @media (max-width: 1200px) {
    > * {
      width: 33.33%;
    }
  }

  @media (max-width: 991px) {
    > * {
      width: 50%;
    }
  }

  @media (max-width: 576px) {
    margin-left: 0;
    margin-right: 0;

    > * {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 5px;
      width: 100%;
    }
  }
}