.notifications-page {
  padding-bottom: 40px;
}

.card-notification {
  &:hover {
    text-decoration: none;
  }

  .created_at {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .message {
    font-weight: 600;
  }

  &.seen {
    .message {
      font-weight: 400;
    }
  }
}