/* Arquitecta Font-Family */
@font-face {
  font-family: 'Arquitecta';
  src: url('/assets/fonts/Arquitecta/Arquitecta-Regular.woff2') format('woff2'),
  url('/assets/fonts/Arquitecta/Arquitecta-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arquitecta';
  src: url('/assets/fonts/Arquitecta/Arquitecta-Medium.woff2') format('woff2'),
  url('/assets/fonts/Arquitecta/Arquitecta-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arquitecta';
  src: url('/assets/fonts/Arquitecta/Arquitecta-Bold.woff2') format('woff2'),
  url('/assets/fonts/Arquitecta/Arquitecta-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arquitecta';
  src: url('/assets/fonts/Arquitecta/Arquitecta-Heavy.woff2') format('woff2'),
  url('/assets/fonts/Arquitecta/Arquitecta-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}