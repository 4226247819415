.dashboard-page {
  padding-bottom: 40px;

  .hero-page {
    height: calc(var(--h1-size) * 3.28);
    background-image: url('../../assets/images/jump-media-group.jpg');
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(35, 31, 32, 0.5);
      z-index: 0;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    h1 {
      color: var(--color-white);
      margin-bottom: 0;
    }
    
    svg {
      width: var(--h1-size);
      margin-left: calc(var(--h1-size) / 2.5);
    }
  }

  .cards-container {
    display: flex;
    position: relative;

    @media (max-width: 576px) {
      display: block;
    }

    .swiper-cards {
      width: 81%;
      position: initial;
      padding-right: 15px;

      @media (max-width: 1600px) {
        width: 76%;
      }

      @media (max-width: 1200px) {
        width: 68%;
      }

      @media (max-width: 991px) {
        width: 51%;
      }

      @media (max-width: 576px) {
        width: 100%;
        position: relative;
        margin-bottom: 15px;
      }
    }

    .card-cta {
      flex: 1;

      @media (max-width: 576px) {
        padding: 0 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .dashboard-page {
    padding-bottom: 15px;
  }
}