.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin-top: 20px;

  button {
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  .page-link {
    padding: 8px 16px;
    display: flex;
    font-size: 20px;
    border: 1px solid var(--color-dark-gray);
    margin-right: -1px;
    transition: all .1s ease-in-out;
  }

  .active {
    .page-link {
      background-color: var(--color-dark);
      color: var(--color-white);
    }
  }

  button {
    cursor: pointer;
  }

  .page-item:hover:not(.active):not(.disabled) {
    .page-link {
      background-color: var(--color-bright-gray-50);
    }
  }

  .page-item {
    &:first-child {
      .page-link {
        border-radius: 4px 0 0 4px;
      }
    }

    &:last-child {
      .page-link {
        border-radius: 0 4px 4px 0;
      }
    }

    &.disabled {
      .page-link {
        color: var(--color-dark-gray);
      }
    }
  }
}