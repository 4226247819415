.quote-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    flex: 1;
  }
}

.modal-quote {
  .btn-quote {
    width: 100%;
    height: 3.5rem;
    background-color: #B0B0B0;
    border: none;
    border-radius: 0.3rem;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
  }

  .btn-quote.accept {
    background-color: #006106;
  }

  .btn-quote.reply {
    background-color: #AA7200;
  }

  .btn-quote.reject {
    background-color: #C5091E;
  }

  .btn-quote:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  svg {
    height: 100px;
    margin-bottom: 15px;
  }

  .btn-wrapper {
    margin-top: 35px;
  }

  .btns {
    display: flex;
    align-items: center;
  }

  .btns .btn-quote {
    width: 50%;
    margin-right: 30px;
  }

  .btn-link {
    font-size: 1.3rem;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
    text-decoration: underline;
    cursor: pointer;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  textarea {
    margin-top: 30px;
  }
}