.sort-by {
  display: flex;
  align-items: center;

  label {
    font-size: 20px;
    margin-left: 10px;
  }

  select {
    margin: 0;
    padding: 0 10px;
    outline: none;
    border: none;
    -webkit-appearance: none;
    width: fit-content;
    cursor: pointer;

    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  svg {
    font-size: 20px;
  }
}