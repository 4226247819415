.ticket-options {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .option {
    display: flex;
    gap: 15px;
    position: relative;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      font-size: 20px;

      &:not(.name) {
        display: flex;
        align-items: flex-start;
      }

      img {
        margin-top: 2px;
      }
    }

    .name {
      text-align: right;
      max-width: 30%;
      width: 100%;
    }

    .fa-circle-plus {
      path {
        fill: var(--color-blue);
      }
    }

    .colored-select {
      padding-left: 20px;
    }

    .select-color {
      position: absolute;
      background-color: var(--select-color);
      width: 6px;
      height: 6px;
      border-radius: 100%;
      left: calc(30% + 25px);
    }

    select {
      background-color: transparent;
      margin-top: 0;
      width: fit-content;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}