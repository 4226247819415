.card-product {
  border-radius: 6px;
  overflow: hidden;
  background-color: var(--color-dark);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  height: 100%;
  position: relative;

  * {
    color: var(--color-white);
    position: relative;
    z-index: 1;
  }

  &.light {
    background-color: var(--color-bright-gray);

    * {
      color: var(--color-black);
    }
  }

  &.hasBg {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(35, 31, 32, 0.5);
      z-index: 0;
    }
  }

  p {
    margin-top: 10px;
  }

  .btn-wrapper {
    margin-top: auto;

    > a, > button {
      margin-top: 30px;
    }
  }
}

@media (max-width: 776px) {
  .card {
    padding: 15px;
  }
}