.form-page {
  position: relative;
  min-height: calc(100vh - var(--header-footer-height));
  padding-bottom: var(--header-footer-height);

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 0;
  }

  &::before {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/jump-media-group.jpg');
  }

  &::after {
    background: transparent linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0.84) 28%, rgba(255, 255, 255, 0) 100%) 0 0 no-repeat padding-box;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .header-container,
  .footer-container {
    position: absolute;
    left: 0;
    right: 0;
  }

  .header-container {
    top: 0;
  }

  .footer-container {
    bottom: 0;
  }
}

@media (max-width: 576px) {
  .form-page {
    &::before,
    &::after {
      display: none;
    }
  }
}