@import "variables";
@import "ck-editor-styles";

body, html, * {
  color: var(--color-black);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-primary);
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

h1 {
  font-size: var(--h1-size);
  margin-bottom: 45px;

  &.mb-0 {
    margin-bottom: 0;
  }
}

h2 {
  font-size: 40px;
  margin-bottom: 30px;
}

h4 {
  font-size: 25px;
}

a {
  background-color: transparent;
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  max-width: 100%;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

ul {
  padding-left: 1.2em;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.label {
  font-weight: 700;
  margin-bottom: 10px;
}

.app {
  padding-top: var(--header-footer-height);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.pt-page {
  padding-top: 60px;
}

.empty {
  padding-top: 45px;
}

.underline {
  text-decoration: underline;
}

.flex-1 {
  flex: 1;
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.gap-3 {
  gap: 15px;
}

.gap-6 {
  gap: 30px;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.columns-md {
  flex-wrap: wrap;
}

.swiper-slide {
  height: auto;
}

.text-md {
  font-size: 20px;
}

.input-field {
  width: 100%;
  max-width: 583px;

  label {
    font-weight: 700;
  }

  .required {
    color: var(--color-error);
  }

  textarea {
    height: 60px;
    padding: 10px;
  }
}

.input-wrapper {
  margin-bottom: 15px;
}

.error-message {
  font-size: 16px;
  margin-top: 3px;
  color: var(--color-error);
}

.columns {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
  flex-wrap: wrap;
  row-gap: 16px;

  > * {
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
  }
}

.no-header {
  margin-top: calc(var(--header-footer-height) * -1);
}

.bb {
  border-bottom: 1px solid var(--color-black);
}

.bt {
  border-top: 1px solid var(--color-black);
}

.pt-4 {
  padding-top: 20px;
}

.pb-6 {
  padding-bottom: 30px;
}

.mr-1 {
  margin-right: 5px;
}

.xl-icon {
  width: 20px;
  height: auto;
}

.tickets-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}

.modal {
  position: absolute;
  max-width: 632px;
  width: 90%;
  max-height: 90%;
  background: #E0E0E0;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 35px 40px 40px;
  font-size: 1.3rem;
  line-height: 1.2;

  .close {
    box-sizing: content-box;
    width: 20px;
    height: 20px;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    background-size: contain;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: all .15s;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 576px) {
    padding: 20px;
  }
}

.modal-invite {
  background-color: #fff;
}

@media (min-width: 577px) {
  .container {
    width: 540px;
  }

  .columns-sm {
    > *:not(:last-child) {
      margin-right: 15px;
    }
  }

  .col-4 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }

  .col-6 {
    width: 50%;
  }

  .col-4 {
    width: 33.33333333%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }

  .columns-lg {
    > *:not(:last-child) {
      margin-right: 15px;
    }
  }
}

@media (min-width: 1201px) {
  .container {
    width: 1140px;
  }

  .columns-xl {
    > *:not(:last-child) {
      margin-right: 15px;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1320px;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 1450px;
  }
}

@media (max-width: 1440px) {
  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 21px;
  }

  .text-md {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .pt-page {
    padding-top: 45px;
  }

  .columns-xl {
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-bottom: 15px;
      flex: initial;
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .columns-lg {
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-bottom: 15px;
      flex: initial;
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  h1 {
    margin-bottom: 35px;
  }

  h2 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .columns-md {
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-bottom: 15px;
      flex: initial;
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  h2 {
    font-size: 24px;
  }

  .columns-sm {
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-bottom: 15px;
      flex: initial;
      width: 100%;
    }
  }
}
