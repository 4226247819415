.main-header {
  .show-mobile {
    display: none;
  }

  .navbar {
    height: var(--header-footer-height);
    padding: 0 33px;
    background-color: var(--color-red);
    color: var(--color-white);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    > div {
      display: flex;
      align-items: center;
    }

    img {
      max-height: 55px;
      vertical-align: middle;
    }

    .logo {
      height: 55px;
      width: 100px;
      -webkit-background-size: contain;
      background-size: contain;
      background-position: left center;
      background-repeat: no-repeat;
    }

    .nav-logos {
      font-size: 30px;

      > *:not(:last-child) {
        margin-right: 20px;
      }
    }

    .nav-links {
      > *:not(:last-child) {
        margin-right: 30px;
      }
    }
    
    .client-name {
      color: #fff;
      font-weight: 700;
    }

    .select_client {
      width: auto;
      margin-top: 0;
    }
  }

  .nav-link {
    color: var(--color-white);
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
    
    &:hover {
      text-decoration: none;
    }
  }

  .badge {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-white);
    font-weight: 700;
    border: 3px solid var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding-bottom: 2px;
  }

  .offcanvas-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-width: 90%;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(105%);
    padding: calc(var(--header-footer-height) + 34px) 30px 30px;
    background-color: var(--color-bright-gray);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    z-index: 9;

    &.show {
      transform: none
    }

    .hide-btn {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      margin-left: auto;
      margin-bottom: 60px;

      svg {
        vertical-align: middle;
        width: 20px;
        margin-left: 10px;
      }
    }
    
    * {
      color: var(--color-black);
    }

    .badge {
      border-color: var(--color-black);
    }

    .menu-links {
      h4 {
        margin-bottom: 0.6em;
        margin-top: 1.6em;

        &:first-of-type {
          margin-top: 0;
        }
      }

      p {
        margin-bottom: 0.5em;
      }
    }

    .logout {
      cursor: pointer;
    }
  }
}

@media (max-width: 1440px) {
  .main-header {
    .navbar {
      .nav-logos {
        > *:not(:last-child) {
          margin-right: 10px;
        }
      }

      .nav-links {
        > *:not(:last-child) {
          margin-right: 15px;
        }
      }

      img {
        max-height: 40px;
      }

      .logo {
        height: 40px;
      }
    }

    .nav-link {
      font-size: 21px;
    }

    .badge {
      width: 35px;
      height: 35px;
      border-width: 2px;
      padding-bottom: 0;
    }

    .offcanvas-menu {
      .hide-btn {
        margin-bottom: 35px;
      }

      .menu-links {
        h4 {
          margin-top: 1.2em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .main-header {
    .show-mobile {
      display: block;
    }

    .navbar {
      padding: 0 15px;

      .nav-links {
        .notifications-link,
        .select_client {
          display: none;
        }
      }
    }

    .offcanvas-menu {
      .menu-links {
        h4:first-of-type {
          margin-top: 1.2em;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .main-header {
    .navbar {

    }
  }
}