.project-page-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    flex: 1;
  }

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}