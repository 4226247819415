.auth-page {
  position: relative;
  min-height: 100vh;
  padding: calc(var(--header-footer-height) + 20px) 0;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-image: url('../assets/images/jump-media-group.jpg');

  &::before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--color-red);
    opacity: .8;
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    * {
      color: var(--color-white);
    }
  }

  .logo-jump {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 75px;
  }

  .auth-form-container {
    background-color: var(--color-white);
    border-radius: 6px;
    width: 598px;
    max-width: 100%;
    padding: 30px;
    margin-left: auto;
    margin-right: auto;

    h2 {
      font-size: 30px;
      margin-bottom: 1em;
    }

    button {
      width: 100%;
    }

    .links-form {
      margin-top: 30px;

      p:not(:last-child) {
        margin-bottom: 1em;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 1680px) {
  .auth-page {
    padding-top: var(--header-footer-height);

    .logo-jump {
      height: 80px;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 1440px) {
  .auth-page {
    .logo-jump {
      height: 70px;
      margin-bottom: 35px;
    }

    .auth-form-container {
      h2 {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 767px) {
  .auth-page {
    padding-top: 30px;

    .auth-form-container {
      h2 {
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 576px) {
  .auth-page {
    .auth-form-container {
      padding: 20px;
    }
  }
}