.user-profile {
  align-items: center;
  margin-bottom: 15px;

  .profile-icon {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    overflow: hidden;

    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
    
    .initials {
      background: #E8959E;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      color: var(--color-white);
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;

      > img {
        max-width: 14px;
        max-height: 14px;
        margin: auto;
      }
    }
  }

  .status {
    font-size: 20px;
    margin-left: 30px;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    span {
      width: 10px;
      height: 10px;
      margin-right: 6px;
      border-radius: 3px;
      display: block;
      background-color: var(--color-medium-gray);

      &.new {
        background-color: #f50000;
      }

      &.opened {
        background-color: var(--color-medium-gray);
      }

      &.in-progress {
        background-color: var(--color-blue);
      }

      &.blocked {
        background-color: #7f25a2;
      }

      &.closed {
        background-color: #6bc950;
      }
    }
  }

  .email {
    margin-left: 10px;
    font-weight: bold;
    font-size: 20px;
    margin-right: 30px;
  }

  .date {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .user-profile {
    .email {
      margin-right: 15px;
    }

    .status {
      margin-left: 20px;
    }
  }
}