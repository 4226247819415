input:not([type="checkbox"]):not([type="radio"]),
select,
textarea {
  font-size: 20px;
  width: 100%;
  margin-top: 10px;
  padding: 0 10px;
  height: 43px;
  background-color: var(--color-white);
  border: 1px solid var(--color-dark);
  border-radius: 6px;
  resize: none;

  &:focus-visible,
  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: 0 0 1px 1px rgba(35, 31, 32, .5);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
}