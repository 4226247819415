.button-back {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  margin-left: 33px;
  margin-top: 33px;
  margin-bottom: 6px;
  margin-right: auto;

  svg {
    width: 20px;
    margin-right: 10px;
  }
}