.button-primary {
  font-weight: 700;
  line-height: 1.15;
  border: 1px solid var(--color-dark);
  color: var(--color-white);
  background-color: var(--color-dark);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 9px 12px;
  text-align: center;
  cursor: pointer;
  transition: all .2s ease-in-out;
  
  &:hover {
    text-decoration: none;
  }

  &.outline {
    border-color: var(--color-black);
    color: var(--color-black);
    background-color: transparent;
  }

  &.light {
    border-color: var(--color-white);
    color: var(--color-white);
  }

  &.grey {
    background-color: var(--color-dark-gray);
    color: var(--color-white);
    border-color: transparent;
  }

  &.text {
    color: var(--color-black);
    border: none;
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
  }
  
  &.error {
    color: #d32f2f;

    &:hover {
      background-color: rgba(211, 47, 47, 0.04);
    }
  }

  &[type="submit"] {
    margin-top: 30px;
  }

  &:disabled {
    opacity: .4;
    pointer-events: none;
  }
}
