.ticket-card {
  padding: 30px;
  background-color: var(--color-bright-gray-50);
  border-radius: 6px;
  height: fit-content;
  display: block;

  &:hover {
    text-decoration: none;
  }

  .content {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .comment-container {
    margin-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .icon-flag {
    font-size: 20px;
  }

  .icon-dot {
    font-size: 6px;
  }

  .icon-message {
    font-size: 20px;
  }

  .ticket-card-footer {
    display: flex;
    align-items: center;
    padding-top: 20px;
    gap: 10px;

    .ticket-id {
      font-size: 20px;
    }

    .fa-flag {
      path {
        fill: var(--color-medium-gray);
      }

      &.low {
        path {
          fill: #6bc950;
        }
      }

      &.high {
        path {
          fill: var(--color-yellow);
        }
      }

      &.urgent {
        path {
          fill: #f50000;
        }
      }
    }

    .reply {
      font-size: 20px;
      color: var(--color-blue);
      cursor: pointer;

      path {
        fill: var(--color-blue);
      }
    }

    .comments {
      span {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ticket-card {
    padding: 20px;
  }
}
