.product-page {
  padding-bottom: 40px;

  h1 {
    margin-bottom: 0;
  }

  .col-card {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .product-page {
    padding-bottom: 15px;
  }
}