.cards-list {
  padding-top: 45px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-dark);

  &:last-of-type {
    border-bottom: none;
  }

  &.no-border {
    border-bottom: none;
  }

  .title-wrapper {
    margin-bottom: 30px;

    h2 {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 767px) {
  .cards-list {
    padding-top: 35px;
    padding-bottom: 25px;

    .title-wrapper {
      margin-bottom: 20px;
    }
  }
}