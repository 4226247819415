.ticket-comment {
  .card-wrapper {
    margin-left: 30px;

    .reply-button {
      margin-top: 20px;
    }

    svg path {
      color: #2878F0;
    }
  }

  .files {
    margin-top: 15px;
  }

  .form-comment {
    padding-left: 20px;
    padding-right: 20px;
  }
}