.button-with-icon {
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg, img {
    width: 20px;
    margin-right: 10px;
  }
}