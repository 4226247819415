@import "fonts";

:root {
  /*
  1. Colors
  2. Fonts
  3. Dimensions
  */

  /************* 1. Colors *************/
  --color-black: #000;
  --color-white: #fff;
  --color-medium-gray: #B0B0B0;
  --color-bright-gray: #ECECEC;
  --color-bright-gray-50: rgba(236, 236, 236, 0.5);
  --color-dark-gray: #888888;
  --color-dark: #231F20;
  --color-red: #C5091E;
  --color-error: #dc3545;
  --color-blue: #2878F0;
  --color-yellow: #DFC100;

  /************* 2. Fonts *************/
  --font-primary: 'Arquitecta', sans-serif;

  /************* 3. Dimensions **********/
  --vh: 100vh;
  --header-footer-height: 80px;
  --h1-size: 50px;

  @media(max-width: 1440px) {
    --header-footer-height: 60px;
    --h1-size: 40px;
  }

  @media (max-width: 767px) {
    --h1-size: 32px;
  }

  @media (max-width: 576px) {
    --h1-size: 30px;
  }
}

