.files-upload {
  label {
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
  }

  .btn-upload {
    pointer-events: none;
  }

  .instructions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .chip {
    margin-right: 4px;
    margin-left: 4px;
  }
}

.file-name {
  margin-bottom: 8px;
  font-size: 18px;
  display: inline-flex;
  overflow-wrap: anywhere;

  svg {
    height: 18px;
    vertical-align: middle;
  }
}

p.file-name {
  display: flex;
}

a.file-name {
  margin-bottom: 0;
}

.chip {
  background-color: #ebebeb;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  line-height: 1;
  display: inline-block;
}