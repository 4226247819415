.ticket-page {
  .form-comment {
    margin-top: 30px;
    margin-left: 30px;
    display: none;

    &.show {
      display: block;
    }
  }

  .user-profile {
    &:not(.wo-email) {
      .date {
        margin-left: 0;
      }
    }

    .date {
      margin-left: 8px;
    }
  }

  .ticket-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      margin-bottom: 0;
    }

    .profile-container {
      display: flex;
      align-items: flex-start;
      gap: 30px;
    }
  }

  .ticket-description {
    padding: 30px;
    border-radius: 6px;
    background-color: var(--color-bright-gray);

    p {
      font-size: 20px;
    }
  }

  .reply-button {
    margin-top: 30px;
  }

  .ticket-container {
    display: flex;
    gap: 50px;

    .ticket-content {
      flex: 1;
    }

    .ticket-info {
      width: 400px;
    }
  }

  .comments-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 1px;
      left: 12px;
      z-index: -1;
      background-color: var(--color-black);
    }

    .replies {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-left: 30px;
      margin-top: 30px;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        left: 12px;
        z-index: -1;
        background-color: var(--color-black);
      }
    }

    .reply-ticket {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .reply-ticket {
      padding-left: 30px;
    }

    .files-wrapper {
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .ticket-page {
    .ticket-container {
      gap: 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .ticket-page {
    .ticket-container {
      flex-direction: column;

      .ticket-options {
        width: 100%;
      }
    }

    .user-profile {
      .date {
        margin-left: 0;
      }

      &:not(.wo-email) {
        .date {
          margin-left: 30px;
        }
      }
    }
  }
}


