.swiper-cards {
  position: relative;

  .swiper {
    position: initial;
  }

  .nav-arrow,
  .swiper-button-next,
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background-color: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    z-index: 1;

    &:disabled {
      opacity: .35;
      cursor: initial;
    }

    svg {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .prev-btn,
  .swiper-button-prev {
    left: -60px;
  }

  .next-btn,
  .swiper-button-next{
    right: -60px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='%23B0B0B0'%3E%3Cpath d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E");
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      content: "";
      width: 100%;
      height: 100%;
    }
  }

  .swiper-button-prev {
    &::after {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 1440px) {
  .swiper-cards {
    .nav-arrow,
    .swiper-button-next,
    .swiper-button-prev {
      width: 30px;
      height: 30px;
    }

    .prev-btn,
    .swiper-button-prev {
      left: -45px;
    }

    .next-btn,
    .swiper-button-next {
      right: -45px;
    }
  }
}

@media (max-width: 1366px) {
  .swiper-cards {
    .prev-btn,
    .swiper-button-prev {
      left: -35px;
    }

    .next-btn,
    .swiper-button-next {
      right: -35px;
    }
  }
}

@media (max-width: 576px) {
  .swiper-cards {
    padding: 0 15px;

    .prev-btn,
    .swiper-button-prev {
      left: -15px;
    }

    .next-btn,
    .swiper-button-next {
      right: -15px;
    }
  }
}